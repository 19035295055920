import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import iosIcon from "../../assets/app-store-ios.svg";
import androidIcon from "../../assets/app-store-play.svg";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  padding: 16px;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 0 0 4px;
`;

const Prompt = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 0;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 17px;
  align-items: flex-start;

  a {
    line-height: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PROFESSIONALS_ID_ANDROID = "com.psychologytoday.members";
const SESSIONS_ID_ANDROID = "com.psychologytoday.clients";
const PROFESSIONALS_ID_IOS = "1580778155";
const SESSIONS_ID_IOS = "1580778922";

/**
 * @param string app App Name, one of "professionals" or "sessions"
 */
const AppStoreLinks = ({ app }) => {
  const [
    sessionsTitle,
    sessionsPrompt,
    professionalsTitle,
    professionalsPrompt,
    iosAltText,
    androidAltText,
  ] = useCopy([
    "appLinks.sessions.title",
    "appLinks.sessions.prompt",
    "appLinks.professionals.title",
    "appLinks.professionals.prompt",
    "appLinks.ios.alt",
    "appLinks.android.alt",
  ]);

  return (
    <Wrapper>
      <Title>{app === "sessions" ? sessionsTitle : professionalsTitle}</Title>
      <Prompt>
        {app === "sessions" ? sessionsPrompt : professionalsPrompt}
      </Prompt>
      <LinkWrapper>
        <a
          href={`https://apps.apple.com/us/app/psychology-today-sessions/id${
            app === "sessions" ? SESSIONS_ID_IOS : PROFESSIONALS_ID_IOS
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={iosIcon} alt={iosAltText} />
        </a>
        <a
          href={`https://play.google.com/store/apps/details?id=${
            app === "sessions" ? SESSIONS_ID_ANDROID : PROFESSIONALS_ID_ANDROID
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={androidIcon} alt={androidAltText} />
        </a>
      </LinkWrapper>
    </Wrapper>
  );
};

export default AppStoreLinks;
