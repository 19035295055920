import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainParticipant from "../MainParticipant";
import GridView from "../GridView";
import SecondaryParticipants from "../SecondaryParticipants";
import SessionControls from "../SessionControls";
import useWindowSize from "../../hooks/useWindowSize";
import useVideoContext from "../../hooks/useVideoContext";
import useInactive from "../../hooks/useInactive";
import { NotificationProvider } from "../Notifications";
import InSessionNotifications from "./InSessionNotifications";
import ConnectivityNotifications from "./ConnectivityNotifications";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";
import DisconnectPrompt from "./DisconnectPrompt";
import ElapsedTime from "../SessionControls/ElapsedTime";
import Reconnecting from "./Reconnecting";
import SettingsMenu from "../SettingsMenu";
import ExitConfirmation from "./ExitConfirmation";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { SessionChat, useChatContext } from "../Chat";
import InSessionWaitingRoom, {
  useInSessionWaitingRoomContext,
  InSessionWaitingRoomProvider,
} from "../InSessionWaitingRoom";
import VideoAndMicSettings from "../VideoAndMicSettings";
import { appInstanceId } from "../../App";
import { datadogEvent } from "../../datadog";

const PageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height + "px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.default};
  z-index: 1;
`;

const BackgroundCover = styled.div`
  background-color: ${({ theme }) => theme.colors.default};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ControlsWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
`;

const TopButtonWrap = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  left: 10px;

  & > * {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
`;

const SettingsMenuWrap = styled.div`
  position: absolute;
  bottom: 80px;
  right: 10px;
  box-shadow: 0px 4px 15px rgba(44, 45, 48, 0.3);
  border-radius: 5px;

  @media (min-width: 768px) {
    bottom: 100px;
    right: 20px;
  }
`;

const Providers = ({ children }) => {
  return (
    <NotificationProvider>
      <InSessionWaitingRoomProvider>{children}</InSessionWaitingRoomProvider>
    </NotificationProvider>
  );
};

const View = ({ isTherapist }) => {
  const { height } = useWindowSize();
  const {
    participants,
    screenShareParticipant,
    selectedParticipant,
    room: { localParticipant },
  } = useVideoContext();
  const {
    participantListVisible,
    menuOpen: settingsOpen,
    setMenuOpen: setSettingsOpen,
    changingAudioVideoSettings,
    setChangingAudioVideoSettings,
  } = useSessionMenuContext();
  const { room: waitingRoom } = useWaitingRoomContext();
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const [waitingRoomOpen, setWaitingRoomOpen] = useState(false);
  const isInactive = useInactive(3000, { sessionsActivity: 5000 });
  const { isActiveChannel, getUnreadCount } = useChatContext();
  const { notification: waitingRoomNotification } =
    useInSessionWaitingRoomContext();

  const chatOpen = isActiveChannel(appInstanceId);
  const chatUnreadCount = getUnreadCount(appInstanceId);

  const hideControls =
    isInactive &&
    !waitingRoomOpen &&
    !chatOpen &&
    !chatUnreadCount &&
    !waitingRoomNotification &&
    !settingsOpen;

  const viewType =
    !selectedParticipant &&
    (!screenShareParticipant || screenShareParticipant === localParticipant) &&
    participants.length === 2
      ? "grid"
      : "full";

  const closeMediaSettings = () => {
    setChangingAudioVideoSettings(false);

    datadogEvent({
      category: "media_settings",
      feature: "panel",
      event: "closed",
      component: "VideoSession",
    });
  };

  useEffect(() => {
    datadogEvent({
      category: "session",
      event: "started",
      component: "VideoSession",
    });

    const onFullscreenChange = e => {
      datadogEvent({
        category: "session",
        feature: "fullscreen",
        event: document.fullscreenElement ? "enabled" : "disabled",
        component: "VideoSession",
      });
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  return (
    <>
      {isTherapist && !exitConfirmationOpen && <DisconnectPrompt />}
      {exitConfirmationOpen && (
        <ExitConfirmation onClose={() => setExitConfirmationOpen(false)} />
      )}
      <InSessionNotifications isTherapist={isTherapist} />
      <ConnectivityNotifications />
      <BackgroundCover />
      <PageWrap height={height}>
        {viewType === "grid" ? (
          <GridView isTherapist={isTherapist} />
        ) : (
          <MainParticipant
            isTherapist={isTherapist}
            hideParticipantInfo={!hideControls}
          />
        )}
        <SecondaryParticipants
          isTherapist={isTherapist}
          visible={participantListVisible}
        />
        <TopButtonWrap>
          {(isTherapist || waitingRoom.clientTimer) && (
            <ElapsedTime isTherapist={isTherapist} />
          )}
        </TopButtonWrap>
        {settingsOpen && (
          <SettingsMenuWrap>
            <SettingsMenu isTherapist={isTherapist} />
          </SettingsMenuWrap>
        )}
        <VideoAndMicSettings
          active={changingAudioVideoSettings}
          onClose={closeMediaSettings}
          overlap={false}
          testsEnabled={false}
        />
        {isTherapist && (
          <InSessionWaitingRoom
            waitingRoomOpen={waitingRoomOpen}
            setWaitingRoomOpen={setWaitingRoomOpen}
          />
        )}

        <SessionChat channel={appInstanceId} />

        <Reconnecting />

        <ControlsWrap>
          <SessionControls
            isTherapist={isTherapist}
            setExitConfirmationOpen={setExitConfirmationOpen}
            waitingRoomOpen={waitingRoomOpen}
            setWaitingRoomOpen={setWaitingRoomOpen}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            hidden={hideControls}
          />
        </ControlsWrap>
      </PageWrap>
    </>
  );
};

export default function VideoSession({ isTherapist }) {
  return (
    <Providers>
      <View isTherapist={isTherapist} />
    </Providers>
  );
}
