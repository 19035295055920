export const getLocalAudioTrack = tracks =>
  tracks.find(t => t.kind === "audio");

export const getLocalVideoTrack = tracks =>
  tracks.find(t => t.kind === "video");

export const getLoginUrl = redirectUrl => {
  if (!redirectUrl) {
    redirectUrl = window.location.origin + "/dashboard";
  }
  return (
    `${process.env.REACT_APP_MEMBER_WEB}?url=` + encodeURIComponent(redirectUrl)
  );
};

export const sanitizeRoomAlias = roomAlias => {
  return (
    roomAlias
      // replace whitespace with hyphens after first character
      .replace(/\s/g, roomAlias.length > 1 ? "-" : "")
      // limit to 100 chars
      .substring(0, 100)
      // remove characters that require uri encoding
      .split("")
      .filter((char, index) => {
        if (index >= 100) {
          return false;
        }
        try {
          return window.encodeURIComponent(char) === char;
        } catch (e) {}
        return false;
      })
      .join("")
      // force lower-case
      .toLowerCase()
  );
};
