import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SettingsView from "./SettingsView";
import HistoryView from "./HistoryView";
import TherapistRoomView from "./TherapistRoomView";
import Authenticated from "../components/Authenticated";
import DesktopNotification from "../components/DesktopNotification";
import { WaitingRoomProvider } from "../providers/WaitingRoomProvider";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import Websocket from "../components/Websocket";
import SidebarLayout from "../components/SidebarLayout";
import SlideoutLayout from "../components/SlideoutLayout";
import { ChatProvider } from "../components/Chat";
import HelpView from "./HelpView";
import EventBus from "../eventBus";
import { datadogSetUserType } from "../datadog";
import AppInstallBanner from "../components/AppInstallBanner";

const Admin = () => {
  useEffect(() => {
    datadogSetUserType("therapist");
  }, []);

  return (
    <Authenticated>
      <DesktopNotification />
      <ChatProvider eventBus={EventBus}>
        <WaitingRoomProvider>
          <WaitingRoomDetails type="therapist">
            <Websocket isTherapist={true} />
            <SlideoutLayout>
              <SidebarLayout isTherapist={true}>
                <Switch>
                  <Route exact path="/dashboard">
                    <TherapistRoomView />
                  </Route>
                  <Route exact path="/dashboard/settings">
                    <SettingsView />
                  </Route>
                  <Route exact path="/dashboard/history">
                    <HistoryView />
                  </Route>
                  <Route exact path="/dashboard/help">
                    <HelpView />
                  </Route>
                </Switch>
              </SidebarLayout>
            </SlideoutLayout>
            <AppInstallBanner app="professionals" />
          </WaitingRoomDetails>
        </WaitingRoomProvider>
      </ChatProvider>
    </Authenticated>
  );
};

export default Admin;
